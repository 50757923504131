import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const ProjectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 4em auto;
  width: 100%;
  max-width: 1250px;

  ${MEDIA.MIN_TABLET`
    align-items: center;
  `}
`;

export const ProjectInfo = styled.div`
  line-height: 1.6;
  width: 100%;
  max-width: 700px;

  // ${MEDIA.DESKTOP`
  //   flex-direction: column;
  //   width: 100%;
  //   max-width: 100vw;
  // `}
`;

export const StackBox = styled(ProjectBox)`
  margin: 1em auto 4em;
  max-width: 1250px;
`;

export const TextWrapper = styled.div`
  padding: 1em 0.5em;
`;

export const TextUnderline = styled.span`
  // display: block;
  // margin: ${(props) => (props.centered ? '1em auto' : '1em 0')};
  // height: 1px;
  // opacity: 0.5;
  // background: #c2c2c2;
  display: block;
  margin-top: 10px;
  padding-bottom: 4px;
  background-color: #ffc340;
  width: 70px;

  ${MEDIA.TABLET`
    margin-left: 16px;
  `}
`;

export const Description = styled.div`
  padding: 1em 0;
  width: 100%;

  h4 {
    font-weight: bold;
  }

  h5 {
    font-weight: bold;
    margin: 0.5em 0;
    text-align: center;
    font-size: 2em;
  }

  a {
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    border-bottom: 1px solid #f7d68b;
    box-shadow: inset 0 -4px 0 #f7d68b;
    padding: 0 4px;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
`;

export const StickyText = styled.div`
  margin: 0 1em;

  ${MEDIA.MIN_TABLET`
    position: sticky;
    position: -webkit-sticky;
    max-width: 300px;
    height: ${({ height }) => (height ? `${height}px` : '500px')};
    top: 70px;
    margin: 1em 4em 0 1em;
  `}
`;

export const Text = styled.div`
  margin: 0 1rem 2rem;
`;

export const Body = styled.div`
  line-height: 1.6;
  width: 100%;
  max-width: 700px;
`;

export const NavigationWrapper = styled.div`
  margin: 0 auto;
  height: 100px;
  background-color: ${({ background }) => (background ? background : 'white')};
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;

  a {
    color: white;
    text-decoration: none;
  }

  &:visited {
    color: white;
  }
`;

export const NavButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: white;
  transition: 0.3s all;
  border-left: ${(props) => props.line && '0.5px solid #e5e5e5'};
  &:hover {
    background: #f7d68b;
    color: black;
  }
`;

export const NavSubText = styled.span`
  font-size: 0.8em;
  opacity: 0.5;
  text-align: center;
`;

export const BackgroundText = styled.p`
  margin-left: 16px;
  line-height: 1.8;
`;

export const ImgTitle = styled.h1`
  z-index: 2;
  font-size: 2.5em;
  text-transform: capitalize;
  position: absolute;
  max-width: 1050px;
  padding: 0 1rem;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
`;

export const ImgWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  max-width: 850px;
  padding: 1em 0;
`;

export const HeroImgContainer = styled.div`
  height: 600px;
`;

export const BodyImgWrapper = styled(ImgWrapper)`
  max-width: 900px;
`;

export const UsabilityImgWrapper = styled(ImgWrapper)`
  max-width: 700px;
  margin: 4em auto;
`;