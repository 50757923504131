import React from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const BoxTexts = ({ children }) => <Container>{children}</Container>;

const Container = styled.div`
  text-align: left;
  line-height: 1.6;
  width: 100%;
  // border-left: 1px solid #e5e5e5;
  padding-top: 4em;

  ${MEDIA.MIN_TABLET`
    padding-left: 4em;
    width: 60%;
  `}
`;

export default BoxTexts;
