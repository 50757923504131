import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ImgWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 4em 4em 0 0;
`;

ImgWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ImgWrapper;
