import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1em auto;
  padding: 2em 0 0;
  // border-top: 1px solid #e5e5e5;
  // border-bottom: 1px solid #e5e5e5;
  // background: #f5f5f5;

  ${MEDIA.MIN_TABLET`
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: space-between;
    max-width: 1250px;
  `}
`;
