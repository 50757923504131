import React from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const Stack = ({ children }) => <Container>{children}</Container>;

const Container = styled.div`
  width: 100%
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0em 0em 4em;
  
  ${MEDIA.MIN_DESKTOP`
    padding: 0 2rem;
`}
`;

export default Stack;
