import React from 'react';
import PropTypes from 'prop-types';
import { Section } from './box-row.css';

const BoxRows = ({ children, reverse = false }) => (
  <Section reverse={reverse}>{children}</Section>
);

BoxRows.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BoxRows;
