import React from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const StackItem = ({ children }) => <Item>{children}</Item>;

const Item = styled.div`
  display: flex;
  padding-left: 4rem;
`;

// const Item = styled.div`
//   position: relative;
//   margin: auto auto;
//   padding: 3em 0 0;
//   text-align: center;
//   flex: 0 0 32%;

//   h3::after {
//     content: ". . .";
//     display: block;
//     margin: .625rem 0;
//     line-height: 1.2em;
//   }

//   &:not(:first-child)::before {
//     content: ' ';
//     width: 100%;
//     background-color: #e5e5e5;
//     position: absolute;
//     left: 0;

//     ${MEDIA.MIN_TABLET`
//       width: 0.3%;
//       height: 100%;
//       top: 20px;
//       // margin: 0 1.5em 0 0;
//       // transform: translateY(-25%);
//     `}
//    },
// `

export default StackItem;
