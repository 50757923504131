import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem 4rem;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  background-color: ${(props) =>
    props.background ? props.background : `white`};
`;
