import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import BoxRows from 'components/box/box-rows/box-rows';
import Head from 'components/head';
import Img from 'gatsby-image';
import ImgWrapper from 'components/image-wrapper';
import BoxTexts from 'components/box-texts';
import { Text } from 'components/GlobalComponents';
import Stack from 'components/stack';
import StackItem from 'components/stack/stack-item';
import Title from 'components/title';

const About = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <BoxRows>
      <ImgWrapper>
        <Img fluid={data.aboutJson.hero.childImageSharp.fluid} />
      </ImgWrapper>
      <BoxTexts>
        <Text
          dangerouslySetInnerHTML={{
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }}
        />
      </BoxTexts>
    </BoxRows>
    {/* <Box>
      <Stack>
        <StackItem>
          <Title as="h3" size="small">
            Follow Me
          </Title>
          <div>
            <a href="google.com">T</a>
            <a href="google.com">F</a>
            <a href="google.com">I</a>
            <a href="google.com">P</a>
          </div>
        </StackItem>
        <StackItem>
          <Title as="h3">See More of My Projects</Title>
          <div>
            <a href="google.com">T</a>
          </div>
        </StackItem>
        <StackItem>
          <Title as="h3">Want to free consultation?</Title>
          <div>
            <Link to="contact">Send me a message</Link>
          </div>
        </StackItem>
      </Stack>
    </Box> */}
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      hero {
        childImageSharp {
          fluid(maxWidth: 850, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
